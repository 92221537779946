<template lang="pug">
b-container(fluid)
  p.mb-4
    b-link(:to="{name: 'Actividad'}")
      arrow-left-icon.mr-3
      span Regresar
  h3.mb-3
    span Registro
    b-spinner.ml-3.mb-1(variant="primary", small, v-if="loadingData")
  div(v-if="register.registro_id")
    h1.mb-3 {{ register.tipo_registro.capitalize() }}
      h5.d-inline-block.ml-2(style="vertical-align:top;")
        b-badge.uppercase(:variant="getBadgeColor(register.status)", pill) {{ register.status }}

    p
      chevron-up-icon.mr-2.text-success
      span Entrada:
      b.ml-2(v-if="register.entrada") {{ $moment(register.entrada).format('DD [de] MMMM [del] YYYY, HH:mmA') }}
      b.ml-2(v-else) No hay entrada registrada

    p
      chevron-down-icon.mr-2.text-danger
      span Salida:
      b.ml-2(v-if="register.salida") {{ $moment(register.salida).format('DD [de] MMMM [del] YYYY, HH:mmA') }}
      b.ml-2(v-else) No hay salida registrada

    p
      grid-icon.mr-2.text-secondary
      span Cliente:
      b.ml-2 {{ cliente.name }}

    p
      home-icon.mr-2.text-secondary
      span Casa:
      b.ml-2 {{ CasaName }}

    b-row.my-4
      b-col(cols="4", v-viewer="{toolbar: false, navbar: false, movable: false, title: false, rotatable: false, scalable: false}")
        p Identificación
        b-img(:src="register.identity_img", fluid, rounded)
      b-col(cols="4", v-viewer="{toolbar: false, navbar: false, movable: false, title: false, rotatable: false, scalable: false}")
        p Foto persona
        b-img(:src="register.selfie_img", fluid, rounded)
      b-col(cols="4", v-viewer="{toolbar: false, navbar: false, movable: false, title: false, rotatable: false, scalable: false}", v-if="register.tipo_registro == 'carro'")
        p Foto auto
        b-img(:src="register.car_img", fluid, rounded)

    div(v-if="register.Perfil")
      h3.mb-3
        span Perfil

      b-row
        b-col.mb-3(md="6")
          b-form-group(label="Cliente autorizado")
            b-form-input(placeholder="Cliente autorizado", read-only, :value="profileCliente.name")

        b-col.mb-3(md="6")
          b-form-group(label="Casa autorizada")
            b-form-input(placeholder="Casa autorizada", read-only, :value="authCasa")

        b-col.mb-3(v-for="field in fields", :key="field.key", md="6")
          b-form-group(:label="field.label")
            b-img(v-if="field.input == 'img'", :src="register.Perfil[field.key]", fluid, rounded)
            b-form-select(v-else-if="field.input == 'select'", :options="$data[field.options]", :value="register.Perfil[field.key]")
              template(#first) 
                b-form-select-option(disabled, :value="null")  -- Selecciona una opción --
            b-form-input(v-else, :placeholder="field.label", read-only, :value="register.Perfil[field.key]")

      b-button(variant="primary", :to="{name: 'Perfil', params: {perfil_id: register.Perfil.perfil_id} }") Ir a editar Perfil
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const registroController = createNamespacedHelpers("registro");

export default {
  data() {
    return {
      register: {},
      cliente: {},
      profileCliente: {},
      fields: [
        {
          key: 'first_name',
          label: 'Nombre'
        },
        {
          key: 'last_name',
          label: 'Apellido'
        },
        {
          key: 'email',
          label: 'Correo'
        },
        {
          key: 'phone_number',
          label: 'Teléfono'
        },
        {
          key: 'relation_type',
          label: 'Relación',
          input: 'select',
          options: 'relationTypes'
        },
        {
          key: 'identity_img',
          label: 'Identificación',
          input: 'img'
        },
        {
          key: 'placas',
          label: 'Placas'
        },
        {
          key: 'marca',
          label: 'Marca'
        },
        {
          key: 'submarca',
          label: 'Submarca'
        },
      ],
      relationTypesText: [
        "EMPLEADO DOMÉSTICO",
        "MADRE",
        "PADRE",
        "HIJO/A",
        "SOBRINO/A",
        "TÍO/A",
        "PRIMO/A",
        "AMIGO/A",
        "CHOFER",
        "OTRO",
      ],
      relationTypes: [],
      timeout: null,
      shouldReload: true
    };
  },
  methods: {
    ...mapActions(["registro/get", 'casa/get', 'cliente/get']),
    getRegistro() {
      clearTimeout(this.timeout)

      this.loadingData = true;
      this["registro/get"]({
        registro_id: this.$route.params.registro_id,
        callback: async (res) => {
          this.loadingData = false;
          if (this.shouldReload)
            this.timeout = setTimeout(() => { this.getRegistro() }, 1000 * 3)

          if (res.success) {
            this.register = res.resource;
            if (res.resource.cliente_id) {
              try {
                this.cliente = await this.getCliente(res.resource.cliente_id) 
              } catch(e) { 
                console.error(e.message);
                this.$bvToast.toast(
                  `Ocurrió un error obteniendo cliente de registro.`,
                  {
                    title: "Ocurrió un error",
                    variant: "danger",
                  }
                );
              }
            }

            if (res.resource.Perfil && res.resource.Perfil.cliente_id) {
              try {
                this.profileCliente = await this.getCliente(res.resource.Perfil.cliente_id) 
              } catch(e) { 
                console.error(e.message);
                this.$bvToast.toast(
                  `Ocurrió un error obteniendo cliente de perfil.`,
                  {
                    title: "Ocurrió un error",
                    variant: "danger",
                  }
                );
              }
            }
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo el registro. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    getCliente(cliente_id) {
      return new Promise((resolve, reject) => {
        this["cliente/get"]({
          cliente_id: cliente_id,
          callback: (res) => {
            if (res.success) {
              resolve(res.resource)
            } else {
              reject()
            }
          },
        });
      })
    },
    getBadgeColor (status) {
      switch ((status || '').toLowerCase()) {
        case 'entrada':
          return 'success';
          break;
        case 'salida':
          return 'danger';
          break;
        case 'invalido':
          return 'warning';
          break;
        default:
          return 'secondary'
          break;
      }
    }
  },
  computed: {
    CasaName () {
      if (this.cliente && this.cliente.Casas && this.cliente.Casas.length) {
        let casa = this.cliente.Casas.find(c => c.casa_id == this.register.casa_id)
        return casa ? casa.name : this.register.casa_id
      } else {
        return this.register.casa_id
      }
    },
    authCasa () {
      if (this.register.Perfil && this.register.Perfil.casa_id && this.profileCliente && this.profileCliente.Casas) {
        let casa = this.profileCliente.Casas.find(c => c.casa_id == this.register.Perfil.casa_id)
        return casa ? casa.name : ''
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.getRegistro();
    for (let type of this.relationTypesText) {
      this.relationTypes.push({
        value: type,
        text: type,
      });
    }
  },
  beforeDestroy () {
    this.shouldReload = false;
    clearTimeout(this.timeout);
  }
};
</script>

<style lang="scss" scoped>
</style>